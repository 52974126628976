import React, { InputHTMLAttributes } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import { useFormContext } from 'react-hook-form'
import { classNames, getColor } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  name: string
  label: string
  labelClassName?: string
}

export default function InputForm(props: InputProps): JSX.Element {
  const {
    name,
    id,
    label,
    type,
    placeholder,
    readOnly,
    labelClassName,
    disabled,
    required,
  } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  const hasError = !!error

  return (
    <div>
      <label
        htmlFor={id}
        className={classNames(
          'block text-sm font-medium text-white',
          labelClassName
        )}
      >
        {label}
        {required && <span className="pl-1 text-red-500">*</span>}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type={type}
          className={`block w-full pr-10 border-${getColor(
            hasError
          )}-300 text-${getColor(hasError)}-900 placeholder-${getColor(
            hasError
          )}-300 focus:outline-none focus:ring-${getColor(
            hasError
          )}-500 focus:border-${getColor(hasError)}-500 sm:text-sm rounded-md`}
          placeholder={placeholder}
          required={false}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          readOnly={readOnly}
          disabled={disabled}
          {...formMethods.register(name)}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <ErrorMessage error={error} name={name} />
    </div>
  )
}
