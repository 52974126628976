import axios, { AxiosRequestConfig } from 'axios'
import { showAlert } from './alert'
import { getAccessToken, getDeviceId } from './auth.lib'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
})
api.interceptors.request.use((config: AxiosRequestConfig) => {
  const customConfig: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
      DeviceId: getDeviceId(),
    },
  }
  return {
    ...config,
    ...customConfig,
  }
})
const rejectInterceptor = (err: any) => {
  const { response } = err
  if (!response) return Promise.reject(err)
  if (response.status === 409) {
    showAlert(response.data.message.errorCode)
  } else if (response.status === 500) {
    showAlert('에러가 발생하였습니다.')
  }

  return Promise.reject(response)
}
api.interceptors.response.use((res) => res.data, rejectInterceptor)
export default { api }
