declare global {
  interface Window {
    PlaceApp: any
    ReaderApp: any
    AuthApp: any
  }
}
export default function ChannelLib() {
  const sendOnLoad = () => {
    if (window.PlaceApp) {
      window.PlaceApp.postMessage(
        JSON.stringify({ message: 'onLoad', data: window.location.href })
      )
    }
  }

  return {
    sendOnLoad,
  }
}
