import { AlertPropType, alertState } from '../states/alert.state'
import { setRecoilExternalState } from './recoilExternalState'

export const showAlert = (
  props: AlertPropType | string,
  options?: Partial<AlertPropType>
) => {
  if (typeof props === 'string') {
    setRecoilExternalState(alertState, { title: props, ...options })
  } else {
    setRecoilExternalState(alertState, props)
  }
}

export const hideAlert = () => setRecoilExternalState(alertState, null)

export default { showAlert, hideAlert }
