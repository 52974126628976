import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function PinIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 9.28571L6.8 11L11 7M15 8.2C15 12.1764 11.5 15.4 8 19C4.5 15.4 1 12.1764 1 8.2C1 4.22355 4.13401 1 8 1C11.866 1 15 4.22355 15 8.2Z"
        stroke="#131A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PinIcon
