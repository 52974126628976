import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import { classNames } from '../../helpers/common.helper'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  submit?: boolean
}

export default function Button({
  submit,
  children,
  className,
  onClick,
  disabled,
}: ButtonProps) {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={classNames(className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}
