import { api } from '../lib/api'
import { Place } from '../types/Place'

export const getPlaceChildrenApi = async (id?: string): Promise<Place> => {
  return api.get(`/place/${id}/children`)
}
export const getPlaceApi = async (id: string): Promise<Place> => {
  return api.get(`/place/${id}`)
}

export default { getPlaceChildrenApi }
