import React from 'react'
import DoorIcon from '../components/icons/door.icon'
import ElevatorIcon from '../components/icons/elevator.icon'
import StairsIcon from '../components/icons/stairs.icon'
import ToiletIcon from '../components/icons/toilet.icon'
import WheelchairIcon from '../components/icons/wheelchair.icon'
import { PlaceType } from '../enums/place.enum'
import { Place, PlaceListItemType } from '../types/Place'

export const convertPlaceTitle = (floor?: string) => {
  if (!floor) return ''
  const number = Number(floor.replace('층', ''))
  if (number < 0) {
    return `지하 ${Math.abs(number)}층`
  }
  return floor
}

export const loadPlaceNextType = (type?: string) => {
  switch (type) {
    case PlaceType.Building:
      return [
        { text: '층', value: PlaceType.Floor },
        { text: '건물', value: PlaceType.Building },
      ]
    case PlaceType.Floor:
      return [
        { text: '장소', value: PlaceType.Place },
        { text: '편의시설', value: PlaceType.Facilities },
        { text: '오브젝트', value: 'object' },
      ]
    default:
      return [
        { text: '건물', value: PlaceType.Building },
        { text: '오브젝트', value: 'object' },
      ]
  }
}

export const getBuildingId = (place: Place): string => {
  return place.building?.id || place.id
}

export const getPlaceTitle = (place: Place): string => {
  if (place.type === PlaceType.Building) {
    return `${convertPlaceTitle(place.title)} 층 안내`
  }
  if (place.type === PlaceType.Floor) {
    return `${place.building?.title} 안내(${convertPlaceTitle(place.title)})`
  }

  return `${place.building?.title} 안내(${convertPlaceTitle(
    place.floor?.title
  )})`
}

export const floorSort = (a: Place, b: Place) => {
  const aTitle = Number(a.title.replace('층', '')) || a.title
  const bTitle = Number(b.title.replace('층', '')) || b.title
  return aTitle > bTitle ? 1 : -1
}

export const placeSort = (a: Place, b: Place, currentPlace: Place) => {
  if (currentPlace.id === a.id) return -1
  return floorSort(a, b)
}

export const getPlaceIcon = (
  category: string
): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (category) {
    case 'toilet':
      return ToiletIcon
    case 'stairs':
      return StairsIcon
    case 'accessibleToilet':
      return WheelchairIcon
    case 'elevator':
      return ElevatorIcon
    case 'office':
    default:
      return DoorIcon
  }
}

const filterItem = (item: Place, type: 'place' | 'facilities' | 'floor') => {
  return item.type === type
}

export const getPlaceList = (
  place: Place,
  type: 'place' | 'facilities' | 'floor'
): PlaceListItemType[] => {
  const convertItem = (item: Place) => {
    return {
      id: item.id,
      title: item.title,
      active: item.id === place.id,
      icon: getPlaceIcon(item.category),
      path: item.icon?.path,
    }
  }
  if (place.type === PlaceType.Building || place.type === PlaceType.Floor) {
    return (
      place.children
        ?.filter((item) => filterItem(item, type))
        .sort(floorSort)
        .map(convertItem) || []
    )
  }

  return (
    place.floor?.children
      ?.filter((item) => filterItem(item, type))
      .sort(floorSort)
      .map(convertItem) || []
  )
}
export default { loadPlaceNextType, getBuildingId }
