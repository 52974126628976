import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getPlaceApi } from '../api/place.api'
import NotFound from './NotFound'
import { Place } from '../types/Place'
import TitleCard from '../components/place/TitleCard'
import PinIcon from '../components/icons/pin.icon'
import NoticeIcon from '../components/icons/notice.icon'
import ClockIcon from '../components/icons/clock.icon'
import PhoneIcon from '../components/icons/phone.icon'
import LocationIcon from '../components/icons/location.icon'
import {
  getBuildingId,
  convertPlaceTitle,
  getPlaceList,
  getPlaceTitle,
} from '../services/place.service'
import { PlaceType } from '../enums/place.enum'
import UserHeartIcon from '../components/icons/user-heart.icon'
import Spinner from '../components/spinners/Spinner'
import { addPlaceHistoryApi } from '../api/place-history.api'
import { getUserId } from '../lib/auth.lib'
import ChannelLib from '../lib/channel.lib'
import { SecondsButtonStyle } from '../styles/button.style'
import PlaceList from '../components/place/PlaceList'
import AppInstallGuide from '../components/guide/AppInstallGuide'

export default function PlaceView() {
  const [place, setPlace] = useState<Place | null>(null)
  const [pageState, setPageState] = useState('loading')
  const { id, code } = useParams()
  const navigate = useNavigate()
  const userId = getUserId()

  useEffect(() => {
    if (id) {
      // 로그인 상태면 히스토리 저장
      if (userId) addPlaceHistoryApi(userId, id)

      getPlaceApi(id)
        .then((data) => {
          document.title = `${convertPlaceTitle(data.title)} - 다가다`
          setPlace(data)
          window.scrollTo(0, 0)
          ChannelLib().sendOnLoad()
          setPageState('loaded')
        })
        .catch((ex) => {
          if (ex.status === 404) {
            setPageState('notfound')
          }
        })
    }
  }, [id, userId, navigate])

  if (pageState === 'notfound') return <NotFound />

  if (!place)
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="w-16" />
      </div>
    )

  return (
    <>
      <div className="flex flex-col py-10 gap-10 overflow-hidden bg-white sm:rounded-lg">
        <div className="px-8">
          <TitleCard place={place} />
        </div>
        {place.summary && (
          <div className="px-8">
            <div className="flex flex-col gap-3">
              <h4 className="flex flex-row gap-2 text-2xl font-bold text-slate-800">
                <PinIcon className="w-3.5" /> 소개
              </h4>
              <p className="whitespace-pre-line text-base font-normal text-slate-600">
                {place.summary}
              </p>
            </div>
          </div>
        )}
        {place.notice && (
          <div className="px-8">
            <div className="flex flex-col gap-3">
              <h4 className="flex flex-row gap-2 text-2xl font-bold text-slate-800">
                <NoticeIcon className="w-3.5" /> 공지사항
              </h4>
              <p className="whitespace-pre-line text-base font-normal text-slate-600">
                {place.notice}
              </p>
            </div>
          </div>
        )}
        {place.hour && (
          <div className="px-8">
            <div className="flex flex-col gap-3">
              <h4 className="flex flex-row gap-2 text-2xl font-bold text-slate-800">
                <ClockIcon className="w-3.5" /> 운영시간
              </h4>
              <p className="whitespace-pre-line text-base font-normal text-slate-600">
                {place.hour}
              </p>
            </div>
          </div>
        )}
        {place.contactTel && (
          <div className="px-8">
            <div className="flex flex-col gap-3">
              <h4 className="flex flex-row gap-2 text-2xl font-bold text-slate-800">
                <PhoneIcon className="w-3.5" /> 연락처
              </h4>
              <p className="whitespace-pre-line text-base font-normal text-slate-600">
                {place.contactTel && `전화번호: ${place.contactTel}`}
                <br />
                {place.contactName && `담당자명: ${place.contactName}`}
              </p>
              <a
                className="flex flex-col items-center justify-center h-16 rounded-lg bg-slate-200 text-lg font-bold text-slate-500"
                href={`tel:${place.contactTel}`}
              >
                전화하기
              </a>
            </div>
          </div>
        )}
        <div className="px-8 flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            <h3 className="text-2xl font-bold text-slate-800">
              {getPlaceTitle(place)}
            </h3>
            {
              // 기본 건물/장소 일 경우
              place.type === PlaceType.Building && (
                <PlaceList
                  limit={5}
                  items={getPlaceList(place, 'floor')}
                  notFoundMessage="등록된 층이 없습니다."
                  moreButtonMessage="더 보기"
                />
              )
            }
            {
              // 기본 건물/장소가 아닐경우
              place.type !== PlaceType.Building && (
                <>
                  <h4 className="flex flex-row gap-2 text-base font-semibold text-slate-400">
                    <LocationIcon className="w-3.5" />
                    장소안내
                  </h4>
                  <PlaceList
                    limit={5}
                    items={getPlaceList(place, 'place')}
                    notFoundMessage="등록된 장소가 없습니다."
                    moreButtonMessage="장소 더 보기"
                  />
                </>
              )
            }
          </div>
          {
            // 기본 건물/장소가 아닐경우
            place.type !== PlaceType.Building && (
              <div className="flex flex-col gap-3">
                <h4 className="flex flex-row gap-2 text-base font-semibold text-slate-400">
                  <UserHeartIcon className="w-3.5" />
                  편의시설 안내
                </h4>
                <PlaceList
                  limit={5}
                  items={getPlaceList(place, 'facilities')}
                  notFoundMessage="등록된 편의시설이 없습니다."
                  moreButtonMessage="편의시설 더 보기"
                />
              </div>
            )
          }
          {place.type !== PlaceType.Building && (
            <Link
              className={SecondsButtonStyle}
              to={`/place/${getBuildingId(place)}`}
            >
              모든 층 안내 정보
            </Link>
          )}
        </div>
      </div>
      <AppInstallGuide nfcId={code} />
    </>
  )
}
