import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function CloseIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4891 3.51115C15.8069 -1.17006 8.19233 -1.17006 3.51013 3.51115C-1.17004 8.19236 -1.17004 15.8089 3.51013 20.4901C5.85123 22.8302 8.92566 23.9997 12.0001 23.9997C15.0746 23.9997 18.148 22.8302 20.4891 20.4901C25.1703 15.8089 25.1703 8.19236 20.4891 3.51115ZM16.9514 15.5378C17.3426 15.929 17.3426 16.5613 16.9514 16.9524C16.7563 17.1475 16.5002 17.2456 16.2441 17.2456C15.988 17.2456 15.7318 17.1475 15.5367 16.9524L12.0001 13.4148L8.46442 16.9514C8.26835 17.1465 8.0122 17.2446 7.75708 17.2446C7.50097 17.2446 7.24482 17.1465 7.04973 16.9514C6.65856 16.5602 6.65856 15.9269 7.04973 15.5368L10.5854 12.0001L7.04874 8.46347C6.65758 8.0723 6.65758 7.43901 7.04874 7.04883C7.43893 6.65766 8.07222 6.65766 8.46339 7.04883L12 10.5855L15.5367 7.04883C15.9278 6.65766 16.5602 6.65766 16.9513 7.04883C17.3425 7.43901 17.3425 8.0723 16.9513 8.46347L13.4147 12.0001L16.9514 15.5378Z"
        fill="#5EEAD4"
      />
    </svg>
  )
}

export default CloseIcon
