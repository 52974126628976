import React, { useEffect } from 'react'
import Spinner from './Spinner'

function SpinnerPage() {
  useEffect(() => {
    const html = document.querySelector('html') as HTMLElement
    const body = document.querySelector('body') as HTMLElement
    const root = document.querySelector('#root') as HTMLElement

    html.classList.add('h-full')
    body.classList.add('h-full')
    root.classList.add('h-full')
  })
  return (
    <div className="w-full h-full flex items-center justify-center bg-slate-50">
      <Spinner />
    </div>
  )
}

export default SpinnerPage
