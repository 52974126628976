import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function SignUp() {
  return (
    <>
      <div>
        <h1 className="text-3xl text-white font-bold">가입형태 선택</h1>
      </div>
      <div className="flex flex-col gap-2">
        <Link
          to="/signup/email"
          className="h-14 flex justify-center items-center rounded bg-white text-lg font-bold text-slate-700"
        >
          이메일로 가입하기
        </Link>
        <Link
          to="/signup/phone"
          className="h-14 flex justify-center items-center rounded bg-teal-500 text-lg font-bold text-white"
        >
          전화번호로 가입하기
        </Link>
      </div>
    </>
  )
}

export default SignUp
