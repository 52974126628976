import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
export default function FooterLogoIcon({ className }: IconProps) {
  return (
    <svg
      className="footer-logo"
      width="80"
      height="18"
      viewBox="0 0 124 28"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <defs>
        <clipPath id="clip0_152_88">
          <rect
            id="svg_1"
            x="32.99879"
            y="2.99749"
            fill="white"
            height="24"
            width="90"
          />
        </clipPath>
        <clipPath id="clip1_152_88">
          <rect
            id="svg_2"
            x="0.99879"
            y="0.99749"
            fill="white"
            height="24"
            width="24"
          />
        </clipPath>
        <clipPath id="clip2_152_88">
          <rect
            id="svg_3"
            x="66.9834"
            y="704.31201"
            fill="white"
            height="15.7377"
            width="59.0164"
          />
        </clipPath>
        <clipPath id="clip3_152_88">
          <rect
            id="svg_4"
            x="46"
            y="703"
            fill="white"
            height="15.7377"
            width="15.7377"
          />
        </clipPath>
      </defs>
      <g>
        <g id="svg_5">
          <g id="svg_17" clipPath="url(#clip0_152_88)">
            <path
              id="svg_18"
              fill="#292929"
              d="m47.23609,2.99749l-3.5593,0l0,4.575c-1.0484,-0.616 -2.2599,-0.972 -3.5594,-0.972c-3.9296,0 -7.1186,3.228 -7.1186,7.206c0,3.977 3.189,7.205 7.1186,7.205c3.9297,0 7.1187,-3.228 7.1187,-7.205l0,-10.809zm-7.1187,14.202c-1.8455,0 -3.3521,-1.519 -3.3521,-3.393c0,-1.875 1.5066,-3.394 3.3521,-3.394c1.8456,0 3.3522,1.519 3.3522,3.394c0,1.874 -1.5066,3.393 -3.3522,3.393z"
            />
            <path
              id="svg_19"
              fill="#292929"
              d="m55.12669,6.60049c-3.9297,0 -7.1186,3.228 -7.1186,7.206c0,3.977 3.1889,7.205 7.1186,7.205c1.2995,0 2.511,-0.356 3.5593,-0.972l0,0.972l3.5594,0l0,-7.205c0,-3.978 -3.189,-7.206 -7.1187,-7.206zm0,10.599c-1.8456,0 -3.3521,-1.519 -3.3521,-3.393c0,-1.875 1.5065,-3.394 3.3521,-3.394c1.8456,0 3.3522,1.519 3.3522,3.394c0,1.874 -1.5066,3.393 -3.3522,3.393z"
            />
            <path
              id="svg_20"
              fill="#292929"
              d="m70.36839,6.60049c-3.9297,0 -7.1186,3.228 -7.1186,7.206c0,3.977 3.1889,7.205 7.1186,7.205c1.2995,0 2.511,-0.356 3.5593,-0.972c0,0 0.5211,3.361 -3.5593,3.361l0,3.603c7.4704,0 7.1184,-6.958 7.1184,-6.958l0,-6.233c0,-3.978 -3.1887,-7.206 -7.1184,-7.206l0,-0.006zm0,10.599c-1.8456,0 -3.3521,-1.519 -3.3521,-3.393c0,-1.875 1.5065,-3.394 3.3521,-3.394c1.8456,0 3.3522,1.519 3.3522,3.394c0,1.874 -1.5066,3.393 -3.3522,3.393z"
            />
            <path
              id="svg_21"
              fill="#292929"
              d="m85.50379,6.60049c-3.93,0 -7.119,3.228 -7.119,7.206c0,3.977 3.189,7.205 7.119,7.205c1.299,0 2.511,-0.356 3.559,-0.972l0,0.972l3.56,0l0,-7.205c0,-3.978 -3.189,-7.206 -7.119,-7.206zm0,10.599c-1.846,0 -3.352,-1.519 -3.352,-3.393c0,-1.875 1.506,-3.394 3.352,-3.394c1.845,0 3.352,1.519 3.352,3.394c0,1.874 -1.507,3.393 -3.352,3.393z"
            />
            <path
              id="svg_22"
              fill="#292929"
              d="m107.80779,2.99749l-3.56,0l0,4.575c-1.048,-0.616 -2.26,-0.972 -3.559,-0.972c-3.93,0 -7.119,3.228 -7.119,7.206c0,3.977 3.189,7.205 7.119,7.205c3.93,0 7.119,-3.228 7.119,-7.205l0,-10.809zm-7.119,14.202c-1.846,0 -3.352,-1.519 -3.352,-3.393c0,-1.875 1.506,-3.394 3.352,-3.394c1.846,0 3.352,1.519 3.352,3.394c0,1.874 -1.506,3.393 -3.352,3.393z"
            />
            <path
              id="svg_23"
              fill="#292929"
              d="m122.99879,13.80649c0,-3.978 -3.189,-7.206 -7.119,-7.206c-3.929,0 -7.118,3.228 -7.118,7.206c0,3.977 3.189,7.205 7.118,7.205c1.3,0 2.511,-0.356 3.56,-0.972l0,0.972l3.559,0l0,-7.205zm-7.119,3.393c-1.845,0 -3.352,-1.519 -3.352,-3.393c0,-1.875 1.507,-3.394 3.352,-3.394c1.846,0 3.352,1.519 3.352,3.394c0,1.874 -1.506,3.393 -3.352,3.393z"
            />
          </g>
          <g id="svg_24" clipPath="url(#clip1_152_88)">
            <path
              id="svg_25"
              fill="#292929"
              d="m8.97469,12.52149c-0.417,-1.808 -0.2444,-3.465 -0.1186,-4.238c0.0431,-0.262 -0.1258,-0.511 -0.3846,-0.572l-2.3113,-0.557c-2.1746,-0.532 -4.4607,0.723 -5.0286,2.894c-0.5679,2.171 0.7728,4.457 2.9834,4.992l1.9805,0.478c1.9806,0.479 1.5025,2.459 1.5025,2.459l-0.4457,1.848c-0.5284,2.181 0.7261,4.464 2.8971,5.032c2.2106,0.579 4.4571,-0.773 4.9927,-2.984l0.5859,-2.426c0.0647,-0.269 -0.1007,-0.535 -0.3667,-0.611c-4.2594,-1.208 -5.7726,-4.08 -6.2866,-6.319l0,0.004z"
            />
            <path
              id="svg_26"
              fill="#292929"
              d="m21.87899,10.95049l-1.9805,-0.478c-1.9805,-0.478 -1.5025,-2.459 -1.5025,-2.459l0.4781,-1.98c0.5284,-2.1893 -0.8159,-4.3891 -3.0014,-4.9211c-1.0927,-0.266 -2.1926,-0.0611 -3.0804,0.4817c-0.8914,0.5427 -1.5743,1.4269 -1.8367,2.5197l-0.9597,3.9607c0,0 -1.9159,7.922 6.0062,9.838l3.9611,0.96c1.0927,0.266 2.1926,0.061 3.0804,-0.482c0.8879,-0.542 1.5744,-1.427 1.8368,-2.519c0.5284,-2.189 -0.816,-4.389 -3.0014,-4.921z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
