import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

function FullHeightLayout() {
  useEffect(() => {
    const html = document.querySelector('html') as HTMLElement
    const body = document.querySelector('body') as HTMLElement
    const root = document.querySelector('#root') as HTMLElement

    html.classList.add('h-full')
    body.classList.add('h-full')
    root.classList.add('h-full')
  })
  return (
    <div className="px-9 py-24 h-full flex flex-col gap-9 bg-slate-50">
      <Outlet />
    </div>
  )
}

export default FullHeightLayout
