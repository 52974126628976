import Joi from 'joi'
import validator from '../lib/validate'

export const AgreeValidation = validator().object({
  'all-agree': Joi.boolean(),
  agree1: Joi.boolean()
    .valid(true)
    .required()
    .messages({ 'any.only': '필수 약관에 동의가 필요합니다.' }),
})

export const PhoneValidation = validator().object({
  phoneNumber: Joi.string().required(),
})
export const CodeValidation = validator().object({
  code: Joi.string().required(),
})

export const CreateUserValidation = validator().object({
  name: Joi.string().required(),
  email: Joi.string()
    .optional()
    .allow('')
    .email({ tlds: { allow: false } }),
  password: Joi.string().min(8),
  'repeat-password': Joi.any().valid(Joi.ref('password')).required().messages({
    'any.only': '비밀번호가 일치하지 않습니다.',
  }),
})

export default {
  PhoneValidation,
  CodeValidation,
  CreateUserValidation,
  AgreeValidation,
}
