import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function ElevatorIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1011_1949)">
        <path
          d="M15.0133 0H1.9574C0.877589 0 0 0.877589 0 1.9574V19.5838C0 19.8135 0.186512 20 0.416217 20H16.5544C16.7841 20 16.9706 19.8135 16.9706 19.5838V1.9574C16.9706 0.877589 16.0931 0 15.0133 0ZM8.90154 6.57701H12.4649V19.1656H8.90154V6.57701ZM8.06911 19.1676H4.50574V6.57701H8.06911V19.1656V19.1676ZM16.1382 19.1676H13.2993V6.16079C13.2993 5.93109 13.1128 5.74458 12.8831 5.74458H4.08953C3.85982 5.74458 3.67331 5.93109 3.67331 6.16079V19.1656H0.832433V1.9574C0.832433 1.337 1.337 0.832433 1.9574 0.832433H15.0133C15.6337 0.832433 16.1382 1.337 16.1382 1.9574V19.1656V19.1676Z"
          fill="black"
        />
        <path
          d="M14.6247 8.1908C14.395 8.1908 14.2085 8.37731 14.2085 8.60701V9.42767C14.2085 9.65737 14.395 9.84388 14.6247 9.84388C14.8544 9.84388 15.0409 9.65737 15.0409 9.42767V8.60701C15.0409 8.37731 14.8544 8.1908 14.6247 8.1908Z"
          fill="black"
        />
        <path
          d="M14.6247 10.7804C14.395 10.7804 14.2085 10.9669 14.2085 11.1966V12.0173C14.2085 12.247 14.395 12.4335 14.6247 12.4335C14.8544 12.4335 15.0409 12.247 15.0409 12.0173V11.1966C15.0409 10.9669 14.8544 10.7804 14.6247 10.7804Z"
          fill="black"
        />
      </g>
    </svg>
  )
}

export default ElevatorIcon
