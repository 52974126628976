import React, { useEffect, useRef, useState } from 'react'
import queryString from 'query-string'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { checkApp, checkOs } from '../../helpers/common.helper'
import DagadaIcon from '../icons/dagada.icon'
import CloseIcon from '../icons/close.icon'
import { OsType } from '../../enums/common.enum'

const APP_INSTALL_NO_SHOW_KEY = 'app-install-no-show'

type AppInstallGuideComponentProps = {
  onClose: () => void
  onClick: () => void
}
function AppInstallGuideComponent({
  onClose,
  onClick,
}: AppInstallGuideComponentProps) {
  const handleNoShow = () => {
    const expires = dayjs().add(1, 'day').toDate()
    Cookies.set(APP_INSTALL_NO_SHOW_KEY, 'true', {
      expires,
    })
    onClose()
  }
  return (
    <div className="fixed bottom-0 w-full">
      <div className="relative flex flex-col gap-4 items-center m-4 px-8 pb-6 pt-10 border-rounded rounded-2xl bg-teal-500 text-white text-lg">
        <DagadaIcon />
        <p className="leading-5">다가다 앱으로 편리하게 이용하세요.</p>
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-4"
        >
          <CloseIcon />
        </button>
        <button
          type="button"
          onClick={onClick}
          className="border-rounded rounded-full my-3 bg-teal-600 p-4 w-full"
        >
          앱 실행하기
        </button>
        <button
          type="button"
          onClick={handleNoShow}
          className="text-teal-200 text-sm"
        >
          오늘은 모바일웹으로 볼게요!
        </button>
      </div>
    </div>
  )
}

type AppInstallGuideProps = {
  nfcId?: string
}
function AppInstallGuide({ nfcId }: AppInstallGuideProps) {
  const isApp = checkApp()
  const hasCookie = Cookies.get(APP_INSTALL_NO_SHOW_KEY)
  const [open, setOpen] = useState(false)
  const [hasClose, setHasClose] = useState(false)
  const os = checkOs()
  const handleOpenClick = () => {
    /**
안드로이드에서
2. 태깅 했다
3. 페이지로 이동
4. 폰이면 앱 유도 팝업 띄우기
5. 앱 실행
6. 실행 안되면 설치 페이지 이동

아이폰에서
2. 탱깅했다
3. 앱이 설치되어 있으면 앱으로 이동
4. 앱이 설치 안되어 있으면 페이지로 이동
5. 폰이면 앱 유도 팝업 띄우기
6. 설치 페이지 이동
     */
    if (os === OsType.ANDROID) {
      window.location.href = `dagada://scan.dagada.co.kr/${nfcId}`
      setTimeout(() => {
        window.location.href = `https://play.google.com/store/apps/details?id=kr.co.dagada.place`
      }, 500)
    }

    if (os === OsType.IOS) {
      window.open(
        'https://apps.apple.com/kr/app/%EB%8B%A4%EA%B0%80%EB%8B%A4/id6443664302'
      )
    }
  }
  const handleClose = () => {
    setOpen(false)
    setHasClose(true)
  }

  useEffect(() => {
    if (!hasCookie && !hasClose && !isApp) {
      setOpen(true)
    }
  }, [hasCookie, hasClose, isApp])
  if (!open) return null
  return (
    <div>
      <AppInstallGuideComponent
        onClick={handleOpenClick}
        onClose={handleClose}
      />
    </div>
  )
}

export default AppInstallGuide
