/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react'
import { ConfirmationResult } from 'firebase/auth'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import Button from '../buttons/Button'
import Form from '../forms/Form'
import InputForm from '../forms/Input'
import { CodeValidation } from '../../validations/signup-phone.validation'
import { showAlert } from '../../lib/alert'
import { checkUserApi } from '../../api/signup.api'
import { signupPhoneNumberState } from '../../states/signup.state'

type CodeVerifyProps = {
  confirmation: ConfirmationResult | null
  successTo: string
}

export default function CodeVerify({
  confirmation,
  successTo,
}: CodeVerifyProps) {
  console.log('CodeVerify')
  const methods = useForm({
    resolver: joiResolver(CodeValidation),
  })

  const navigate = useNavigate()
  const phoneNumber = useRecoilValue<string | null>(signupPhoneNumberState)
  const handleConfirmation: SubmitHandler<FieldValues> = async (formData) => {
    const { code } = formData
    console.log('handleConfirmation', confirmation, code, confirmation)
    if (!confirmation) return
    confirmation
      .confirm(code)
      .then(async (result) => {
        const existsUser = await checkUserApi(phoneNumber || '')
        if (existsUser) {
          showAlert('이미 가입된 회원이 있습니다.', {
            onClick: async () => {
              navigate('/signin')
            },
          })
        } else {
          console.log('login complete', result)
          navigate(successTo)
        }
      })
      .catch((error) => {
        console.log('error', error)
        if (error.code === 400) {
          showAlert('코드가 잘못되었습니다.')
        }
      })
  }

  if (!confirmation) return <></>

  return (
    <Form
      methods={methods}
      onSubmit={handleConfirmation}
      className="flex flex-col gap-3"
    >
      <InputForm type="number" label="인증번호" id="code" name="code" />
      <Button submit>인증번호 확인</Button>
    </Form>
  )
}
