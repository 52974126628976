import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NotFound from './NotFound'
import { convertPlaceTitle } from '../services/place.service'
import Spinner from '../components/spinners/Spinner'
import { addPlaceHistoryApi } from '../api/place-history.api'
import { getUserId } from '../lib/auth.lib'
import ChannelLib from '../lib/channel.lib'
import { DocumentModel } from '../types/Document'
import { getDocumentApi } from '../api/document.api'
import AppGuide from '../components/guide/AppGuide'
import FooterLogoIcon from '../components/icons/footer-logo.icon'

export default function DocumentView() {
  const [doc, setDoc] = useState<DocumentModel | null>(null)
  const [pageState, setPageState] = useState('loading')
  const { id } = useParams()
  const userId = getUserId()

  useEffect(() => {
    if (id) {
      getDocumentApi(id)
        .then((data) => {
          document.title = `${convertPlaceTitle(data.title)} - 다가다`
          setDoc(data)
          window.scrollTo(0, 0)
          ChannelLib().sendOnLoad()
          setPageState('loaded')
        })
        .catch((ex) => {
          if (ex.status === 404) {
            setPageState('notfound')
          }
        })
    }
  }, [id, userId])

  if (pageState === 'notfound') return <NotFound />

  if (!doc)
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner className="w-16" />
      </div>
    )

  return (
    <div className="relative">
      <div className="document-wrap">
        <main
          className="editor-document"
          dangerouslySetInnerHTML={{ __html: doc.contents }}
        />
        <footer className="mb-2 flex flex-col items-center">
          <AppGuide />
          <FooterLogoIcon />
        </footer>
      </div>
    </div>
  )
}
