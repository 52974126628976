import { v4 } from 'uuid'

const ACCESS_TOKEN_KEY = 'accessToken'
const LOGIN_USER_KEY = 'loginUser'
const DEVICE_ID_KEY = 'deviceId'
const USER_ID_KEY = 'userId'

export const setDeviceId = (id: string) => {
  localStorage.setItem(DEVICE_ID_KEY, id)
}

export const getDeviceId = (): string => {
  // return localStorage.getItem(DEVICE_ID_KEY) || 'b' // 테스트용
  const deviceId = localStorage.getItem(DEVICE_ID_KEY)
  if (!deviceId) {
    const agent = navigator.userAgent
    const regex = /DeviceId\/(?<value>[\w\d-]+)/g
    const matches = regex.exec(agent)

    if (!matches) return ''

    const value = matches[1]
    setDeviceId(value)
    return value
  }
  return deviceId
}

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) || ''
}

export const setAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token)
}

export const setUserId = (id: string) => {
  localStorage.setItem(USER_ID_KEY, id)
}

export const getUserId = (): string => {
  // return localStorage.getItem(USER_ID_KEY) || 'a9f30d6f-99d8-47a7-9d27-6bab6df6595d' // 테스트용
  const deviceId = localStorage.getItem(USER_ID_KEY)
  if (!deviceId) {
    const agent = navigator.userAgent
    const regex = /UserId\/(?<value>[\w\d-]+)/g
    const matches = regex.exec(agent)
    if (!matches) return ''

    const value = matches[1]
    if (!value) return ''

    setUserId(value)
    return value
  }
  return deviceId
}

export default {}
