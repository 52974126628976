/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react'
import { getDocumentApi } from '../api/document.api'
import { NfcContentType } from '../types/Nfc'
import AppGuide from './guide/AppGuide'
import FooterLogoIcon from './icons/footer-logo.icon'

type PageProps = {
  contentType: NfcContentType
  contentId?: string
  contents?: string
}

function Page({ contentType, contentId, contents }: PageProps) {
  const [content, setContent] = useState<string>('')

  useEffect(() => {
    if (contentType === 'document' && contentId) {
      getDocumentApi(contentId).then((data) => {
        document.title = data.title
        setContent(data.contents)
      })
    }
  }, [contentType, contentId])

  return (
    <div className="document-wrap">
      <main
        className="document"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <footer className="mb-2">
        <AppGuide />
        <FooterLogoIcon />
      </footer>
    </div>
  )
}

export default Page
