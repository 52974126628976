import React from 'react'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { classNames } from '../../helpers/common.helper'
import { PlaceListItemType } from '../../types/Place'
import ArrowIcon from '../icons/arrow.icon'
import Icon from './Icon'

interface PlaceListItemProps {
  place: PlaceListItemType
}

function PlaceListItem({ place }: PlaceListItemProps) {
  const { title, id, active, path } = place
  return (
    <li>
      <Link
        to={`/place/${id}`}
        className={classNames(
          'grow flex flex-1 flex-row items-center gap-2 h-12 rounded-lg bg-slate-100 hover:bg-slate-200'
        )}
      >
        <span className="flex flex-row flex-1 item-center gap-2">
          {path && (
            <Icon path={path} alt={`${title} 아이콘`} className="w-3.5 ml-4" />
          )}
          {!path && (
            <place.icon className="w-3.5 ml-4" alt={`${title} 아이콘`} />
          )}
          {title}
          {active && (
            <MapPinIcon className="w-5 text-teal-600" aria-label="현재 장소" />
          )}
        </span>
        <ArrowIcon className="w-10 mr-1" />
      </Link>
    </li>
  )
}

export default PlaceListItem
