import { joiResolver } from '@hookform/resolvers/joi'
import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { signinByPhoneApi } from '../api/signin.api'
import { SigninByPhoneType } from '../api/types/signin.type'
import Button from '../components/buttons/Button'
import Form from '../components/forms/Form'
import InputForm from '../components/forms/Input'
import { showAlert } from '../lib/alert'
import { signupPhoneNumberState } from '../states/signup.state'
import { SigninValidation } from '../validations/signin.validation'

declare global {
  interface Window {
    jwt: string
  }
}

function Signin() {
  const methods = useForm({
    resolver: joiResolver(SigninValidation),
  })
  const navigate = useNavigate()
  const [editable, setEditable] = useState<boolean>(true)
  const phoneNumber =
    useRecoilValue<string | null>(signupPhoneNumberState) || ''

  const handleSubmit: SubmitHandler<FieldValues> = (formData) => {
    setEditable(false)
    signinByPhoneApi(formData as SigninByPhoneType)
      .then((data) => {
        // 일단 변수에 추가하긴 했는데 안될거 같다
        // postMessage를 사용해야될듯
        console.log(data)
        window.jwt = data.accessToken
        showAlert('로그인 후 처리 필요')
        window.close()
      })
      .catch((error) => {
        if (error.status === 404) {
          showAlert('일치하는 회원이 없습니다.')
        }
      })
      .finally(() => {
        setEditable(true)
      })
  }
  return (
    <>
      <div>
        <h1 className="text-3xl text-white font-bold">로그인</h1>
      </div>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className="flex flex-col gap-3"
      >
        <InputForm
          type="tel"
          label="전화번호"
          id="phoneNumber"
          name="phoneNumber"
          disabled={!editable}
          defaultValue={phoneNumber}
        />
        <InputForm
          type="password"
          label="비밀번호"
          id="password"
          name="password"
          disabled={!editable}
        />
        <Button submit disabled={!editable}>
          로그인
        </Button>
      </Form>
    </>
  )
}

export default Signin
