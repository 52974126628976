import React from 'react'

interface IconProps {
  className?: string
}
export default function DagadaIcon({ className }: IconProps) {
  return (
    <svg
      className={className}
      width="107"
      height="28"
      viewBox="0 0 107 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7946 0H12.5949V5.33709C11.3582 4.62064 9.92651 4.20322 8.3952 4.20322C3.75979 4.20322 0 7.96618 0 12.6076C0 17.249 3.75979 21.0119 8.39728 21.0119C13.0348 21.0119 16.7946 17.249 16.7946 12.6076V0ZM8.39728 16.5637C6.21652 16.5637 4.44452 14.7881 4.44452 12.6055C4.44452 10.4229 6.21859 8.64734 8.39728 8.64734C10.576 8.64734 12.3521 10.4229 12.3521 12.6055C12.3521 14.7881 10.578 16.5637 8.39728 16.5637Z"
        fill="white"
      />
      <path
        d="M26.1068 4.20312C21.4693 4.20312 17.7095 7.96609 17.7095 12.6075C17.7095 17.2489 21.4693 21.0119 26.1068 21.0119C27.6381 21.0119 29.0698 20.5944 30.3044 19.878V21.0119H34.504V12.6075C34.504 7.96609 30.7442 4.20312 26.1068 4.20312ZM26.1068 16.5657C23.926 16.5657 22.1519 14.7901 22.1519 12.6075C22.1519 10.4249 23.926 8.64932 26.1068 8.64932C28.2875 8.64932 30.0595 10.4249 30.0595 12.6075C30.0595 14.7901 28.2854 16.5657 26.1068 16.5657Z"
        fill="white"
      />
      <path
        d="M44.0887 4.20312C39.4512 4.20312 35.6914 7.96609 35.6914 12.6075C35.6914 17.2489 39.4512 21.0119 44.0887 21.0119C45.62 21.0119 47.0517 20.5944 48.2863 19.878C48.2863 19.878 48.9025 23.7967 44.0866 23.7967V27.9999C52.901 27.9999 52.4839 19.8801 52.4839 19.8801V12.6075C52.4839 7.96609 48.7241 4.20312 44.0866 4.20312H44.0887ZM44.0887 16.5657C41.9079 16.5657 40.1339 14.7901 40.1339 12.6075C40.1339 10.4249 41.9079 8.64932 44.0887 8.64932C46.2694 8.64932 48.0414 10.4249 48.0414 12.6075C48.0414 14.7901 46.2674 16.5657 44.0887 16.5657Z"
        fill="white"
      />
      <path
        d="M61.9456 4.20312C57.3081 4.20312 53.5483 7.96609 53.5483 12.6075C53.5483 17.2489 57.3081 21.0119 61.9456 21.0119C63.4769 21.0119 64.9086 20.5944 66.1453 19.878V21.0119H70.3429V12.6075C70.3429 7.96609 66.5831 4.20312 61.9456 4.20312ZM61.9456 16.5657C59.7649 16.5657 57.9929 14.7901 57.9929 12.6075C57.9929 10.4249 59.7669 8.64932 61.9456 8.64932C64.1243 8.64932 65.8984 10.4249 65.8984 12.6075C65.8984 14.7901 64.1243 16.5657 61.9456 16.5657Z"
        fill="white"
      />
      <path
        d="M88.2615 0H84.0618V5.33709C82.8251 4.62064 81.3934 4.20322 79.8621 4.20322C75.2246 4.20322 71.4648 7.96618 71.4648 12.6076C71.4648 17.249 75.2246 21.0119 79.8621 21.0119C84.4996 21.0119 88.2594 17.249 88.2594 12.6076V0H88.2615ZM79.8642 16.5637C77.6834 16.5637 75.9114 14.7881 75.9114 12.6055C75.9114 10.4229 77.6855 8.64734 79.8642 8.64734C82.0429 8.64734 83.819 10.4229 83.819 12.6055C83.819 14.7881 82.045 16.5637 79.8642 16.5637Z"
        fill="white"
      />
      <path
        d="M106.181 12.6075C106.181 7.96609 102.421 4.20312 97.7835 4.20312C93.146 4.20312 89.3862 7.96609 89.3862 12.6075C89.3862 17.2489 93.146 21.0119 97.7835 21.0119C99.3148 21.0119 100.747 20.5944 101.983 19.878V21.0119H106.183V12.6096L106.181 12.6075ZM97.7835 16.5636C95.6027 16.5636 93.8307 14.788 93.8307 12.6054C93.8307 10.4228 95.6048 8.64724 97.7835 8.64724C99.9622 8.64724 101.738 10.4228 101.738 12.6054C101.738 14.788 99.9643 16.5636 97.7835 16.5636Z"
        fill="white"
      />
    </svg>
  )
}
