import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import PhoneVerify from '../components/signup/PhoneVerify'

function SignupPhone() {
  return <PhoneVerify successTo="/signup/user" />
}

export default SignupPhone
