import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function StairsIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 17V13H5V9H9V5H13V1H17"
        stroke="#131A29"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 17H17"
        stroke="#131A29"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 1V17"
        stroke="#131A29"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StairsIcon
