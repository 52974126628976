import React, { useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { joiResolver } from '@hookform/resolvers/joi'
import { agree1 } from '../langs/agree1'
import Button from '../components/buttons/Button'
import Form from '../components/forms/Form'
import CheckboxForm from '../components/forms/Chekcbox'
import { AgreeValidation } from '../validations/signup-phone.validation'
import { showAlert } from '../lib/alert'

function Agree() {
  const agreeText1 = agree1
  const methods = useForm({
    resolver: joiResolver(AgreeValidation),
  })
  const navigate = useNavigate()
  const { type } = useParams()
  const [editable, setEditable] = useState<boolean>(true)

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    setEditable(false)
    console.log(formData)
    navigate(`/signup/${type}`)
  }
  const handleAllAgree = (checked: boolean) => {
    if (checked) {
      methods.setValue('agree1', true)
    } else {
      methods.setValue('agree1', false)
    }
  }
  const handleAtherAgree = (checked: boolean) => {
    if (!checked) methods.setValue('all-agree', false)
  }
  const handleCancel = () => {
    showAlert('취소(앱이랑 연동해야함)')
  }

  return (
    <Form methods={methods} onSubmit={handleSubmit} className="space-y-5">
      <div>
        <h1 className="text-3xl text-slate-800 font-bold">사용자 동의</h1>
      </div>
      <fieldset className="space-y-5">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <CheckboxForm
              id="all-agree"
              name="all-agree"
              ariaDescribedBy="all-agree-description"
              disabled={!editable}
              onChange={(e) => {
                handleAllAgree(e.target.checked)
              }}
            />
          </div>
          <div className="ml-3">
            <label
              htmlFor="all-agree"
              className="text-lg font-bold text-slate-800"
            >
              전체동의
              <span
                id="all-agree-description"
                className="pl-2 text-slate-800 font-medium text-sm"
              >
                네이버 이용약관, 개인정보 수집 및 이용, 위치기반서비스
                이용약관(선택), 프로모션 정보 수신(선택)에 모두 동의합니다.
              </span>
            </label>
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <CheckboxForm
              id="agree1"
              name="agree1"
              ariaDescribedBy="agree1-description"
              disabled={!editable}
              onChange={(e) => {
                handleAtherAgree(e.target.checked)
              }}
            />
          </div>
          <div className="ml-3 w-full text-sm">
            <label
              htmlFor="agree1"
              className="text-lg font-bold  text-slate-800"
            >
              네이버 이용약관 동의
              <span className="text-sm text-red-500">(필수)</span>
            </label>
            <textarea
              rows={4}
              name="agree1-comment"
              id="agree1-description"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={agreeText1}
              disabled
            />
          </div>
        </div>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id="offers"
              aria-describedby="offers-description"
              name="offers"
              type="checkbox"
              className="h-4 w-4 rounded border-slate-300 text-teal-600 focus:ring-teal-500"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="offers" className="font-medium text-slate-800">
              Offers
            </label>
            <span id="offers-description" className="text-slate-800">
              <span className="sr-only">Offers </span>when they are accepted or
              rejected by candidates.
            </span>
          </div>
        </div>
      </fieldset>
      <div className="flex flex-row justify-between gap-2">
        <button
          type="button"
          className="w-full 'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-slate-500 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'"
          disabled={!editable}
          onClick={handleCancel}
        >
          취소
        </button>
        <Button className="w-full" submit disabled={!editable}>
          동의
        </Button>
      </div>
    </Form>
  )
}

export default Agree
