const defaultButtonStyle =
  'inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md'
type Style = {
  color?: string
  bgColor?: string
  hoverBgColor?: string
  focusRingColor?: string
  border?: string
  borderColor?: string
}
const getButtonStyle = (textStyle?: Style): string => {
  let result = ''
  if (textStyle?.color) result += ` ${textStyle.color}`
  if (textStyle?.bgColor) result += ` ${textStyle.bgColor}`
  if (textStyle?.hoverBgColor) result += ` ${textStyle.hoverBgColor}`
  if (textStyle?.focusRingColor) result += ` ${textStyle.focusRingColor}`
  if (textStyle?.border) result += ` ${textStyle.border}`
  if (textStyle?.borderColor) result += ` ${textStyle.borderColor}`
  return result
}
export const ButtonStyle = (buttonStyle?: Style): string => {
  return `${defaultButtonStyle} ${getButtonStyle(buttonStyle)}}`
}
export const DefaultButtonStyle = ButtonStyle({
  color: 'text-slate-700',
  bgColor: 'bg-white',
  hoverBgColor: 'hover:bg-slate-50',
  focusRingColor: 'focus:ring-teal-500',
  borderColor: 'border border-slate-300',
})
export const PrimaryButtonStyle = ButtonStyle({
  color: 'text-white',
  bgColor: 'bg-teal-600',
  hoverBgColor: 'hover:bg-teal-700',
  focusRingColor: 'focus:ring-teal-500',
  border: 'border-transparent',
})
export const SecondsButtonStyle = ButtonStyle({
  color: 'text-white',
  bgColor: 'bg-slate-400',
  hoverBgColor: 'hover:bg-slate-500',
  focusRingColor: 'focus:ring-teal-500',
  border: 'border-transparent',
})
