import {
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import { initializeApp } from 'firebase/app'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { PhoneValidation } from '../../validations/signup-phone.validation'
import Button from '../buttons/Button'
import Form from '../forms/Form'
import InputForm from '../forms/Input'
import {
  confirmationState,
  signupPhoneNumberState,
} from '../../states/signup.state'
import CodeVerify from './CodeVerify'
import { showAlert } from '../../lib/alert'

type PhoneVerifyProps = {
  successTo: string
}

function PhoneVerify({ successTo }: PhoneVerifyProps) {
  console.log('PhoneVerify')
  const methods = useForm({
    resolver: joiResolver(PhoneValidation),
  })
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>()
  const [verifying, setVerifying] = useState<boolean>(false)
  const [confirmation, setConfirmation] = useState<ConfirmationResult | null>(
    null
  )
  const setPhoneNumber = useSetRecoilState<string | null>(
    signupPhoneNumberState
  )

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: 'AIzaSyAg7Le5JxhgYiWXTOR1dnI6izrCxrIRke0',
    authDomain: 'dagada-reader.firebaseapp.com',
    projectId: 'dagada-reader',
    storageBucket: 'dagada-reader.appspot.com',
    messagingSenderId: '1072398102175',
    appId: '1:1072398102175:web:0185e5cce2f58f04f4bcaa',
    measurementId: 'G-LVLKD025SQ',
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  const auth = getAuth()
  auth.languageCode = 'kr'

  const handleSendConfirm: SubmitHandler<FieldValues> = async (formData) => {
    setVerifying(true)
    const { phoneNumber } = formData
    const sendPhoneNumber = phoneNumber.replace(/^010-/g, '+82 10-')

    // todo check exists user by device id and phone number
    // const alreadyUser = await checkUserApi()

    setConfirmation(
      ((await signInWithPhoneNumber(
        auth,
        sendPhoneNumber,
        recaptcha as RecaptchaVerifier
      )
        .then((confirmationResult) => {
          setPhoneNumber(phoneNumber)
          return confirmationResult
        })
        .catch((error) => {
          setVerifying(false)
          console.log('handleSendCofirm', error)
        })
        .finally(() => {
          recaptcha?.clear()
        })) as ConfirmationResult) || null
    )
  }

  useEffect(() => {
    console.log('PhoneVerify useEffect, recaptcha init')
    setRecaptcha(
      new RecaptchaVerifier(
        'recaptcha-container',
        {
          'expired-callback': () => {
            showAlert('캡차가 만료 되었습니다.')
          },
        },
        auth
      )
    )
    return () => {
      console.log('recaptcha reset')
      setRecaptcha(undefined)
    }
  }, [auth])
  return (
    <>
      <div>
        <h1 className="text-3xl text-white font-bold">전화번호 인증</h1>
      </div>
      <Form
        methods={methods}
        onSubmit={handleSendConfirm}
        className="flex flex-col gap-3"
      >
        <div className="flex flex-col gap-1">
          <InputForm
            type="tel"
            label="전화번호"
            id="phoneNumber"
            name="phoneNumber"
            disabled={verifying}
            placeholder="010-1234-5678"
          />
        </div>
        <Button submit disabled={verifying}>
          인증코드 받기
        </Button>
      </Form>

      <CodeVerify confirmation={confirmation} successTo={successTo} />
      <div id="recaptcha-container" />
    </>
  )
}

export default PhoneVerify
