/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { joiResolver } from '@hookform/resolvers/joi'
import { showAlert } from '../../lib/alert'
import { signupPhoneNumberState } from '../../states/signup.state'
import Button from '../buttons/Button'
import Form from '../forms/Form'
import InputForm from '../forms/Input'
import { CreateUserValidation } from '../../validations/signup-phone.validation'
import { signupByPhoneApi } from '../../api/signup.api'
import { SignupByPhoneType } from '../../api/types/signup.type'

function CreateUser() {
  const methods = useForm({
    resolver: joiResolver(CreateUserValidation),
  })
  const [editable, setEditable] = useState<boolean>(true)
  const phoneNumber = useRecoilValue<string | null>(signupPhoneNumberState)
  // const phoneNumber = '+1 650-555-3434'
  const navigate = useNavigate()

  console.log('CreateUser', phoneNumber)

  const handleSubmit: SubmitHandler<FieldValues> = (formData) => {
    setEditable(false)
    signupByPhoneApi({
      ...formData,
      phoneNumber,
    } as SignupByPhoneType)
      .then(() => {
        navigate('/signin')
      })
      .finally(() => {
        setEditable(true)
      })
  }

  useEffect(() => {
    if (!phoneNumber) {
      showAlert({
        title: '잘못된 접근 입니다.',
        onClick: async () => {
          window.close()
        },
      })
    }
  })

  if (!phoneNumber) return <></>

  return (
    <>
      <div>
        <h1 className="text-3xl text-white font-bold">회원가입</h1>
      </div>
      <Form
        methods={methods}
        onSubmit={handleSubmit}
        className="flex flex-col gap-3"
      >
        <InputForm
          type="text"
          label="이름"
          id="name"
          name="name"
          disabled={!editable}
          required
        />
        <InputForm
          type="password"
          label="비밀번호"
          id="password"
          name="password"
          disabled={!editable}
          required
        />
        <InputForm
          type="password"
          label="비밀번호 확인"
          id="repeat-password"
          name="repeat-password"
          disabled={!editable}
          required
        />
        <div>
          <InputForm
            type="email"
            label="이메일"
            id="email"
            name="email"
            disabled={!editable}
            placeholder="abc@email.com"
          />
        </div>
        <Button submit disabled={!editable}>
          회원가입 하기
        </Button>
      </Form>
    </>
  )
}

export default CreateUser
