import { CustomerType } from './Customer'
import { SignBoard as SignBoardType } from './SignBoard'

export enum NfcContentTypeEnum {
  Document = 'document',
  Place = 'place',
  SignBoard = 'signBoard',
  Link = 'link',
}
export type NfcContentType = 'document' | 'place' | 'link' | 'signBoard'

export interface Nfc {
  id: string
  code?: string
  serviceId: string
  customer?: CustomerType
  name: string
  contents: NfcContents[]
}

export interface NfcContents {
  type: NfcContentType
  contentId?: string
  contents?: string
  placeId?: string
  documentId?: string
  signBoardId?: string
  signBoard?: SignBoardType
}
