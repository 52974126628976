import React, { InputHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import { classNames } from '../../helpers/common.helper'
import ErrorMessage from '../error-message/ErrorMessage'

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  ariaDescribedBy?: string
}

function CheckboxForm(props: CheckboxProps) {
  const { id, name, ariaDescribedBy, className, disabled, onChange } = props
  const formMethods = useFormContext()
  const error = formMethods?.formState?.errors[name]
  return (
    <>
      <input
        id={id}
        aria-describedby={ariaDescribedBy}
        type="checkbox"
        className={classNames(
          'h-4 w-4 rounded border-slate-300 text-teal-600 focus:ring-teal-500',
          className
        )}
        disabled={disabled}
        {...formMethods.register(name)}
        onChange={onChange}
      />
      {/* <ErrorMessage error={error} name={name} /> */}
    </>
  )
}

export default CheckboxForm
