import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function LocationIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91393 10.6467C5.31859 10.4152 5.02091 10.2995 4.93401 10.1327C4.85867 9.98806 4.85857 9.81581 4.93374 9.67112C5.02044 9.50422 5.31798 9.3881 5.91306 9.15588L12.3008 6.6631C12.838 6.45347 13.1066 6.34865 13.2782 6.40599C13.4272 6.45578 13.5442 6.57275 13.594 6.7218C13.6513 6.89343 13.5465 7.16202 13.3369 7.6992L10.8441 14.0869C10.6119 14.682 10.4958 14.9796 10.3289 15.0663C10.1842 15.1414 10.0119 15.1413 9.86734 15.066C9.70054 14.9791 9.58478 14.6814 9.35325 14.0861L8.51783 11.9378C8.47083 11.817 8.44733 11.7565 8.41104 11.7057C8.37887 11.6606 8.33943 11.6211 8.29433 11.589C8.24345 11.5527 8.18303 11.5292 8.06218 11.4822L5.91393 10.6467Z"
        stroke="#131A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
        stroke="#131A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocationIcon
