/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { createNfcLogApi, createQrLogApi } from '../api/log.api'
import { getNfcContents } from '../api/nfc.api'
import Page from '../components/Page'
import { Nfc, NfcContentTypeEnum } from '../types/Nfc'
import NotFound from './NotFound'
import SpinnerPage from '../components/spinners/SpinnerPage'
import { getQrContents } from '../api/qr.api'

function Entrance() {
  const [nfcInfo, setNfcInfo] = useState<Nfc | null>(null)
  const { code } = useParams()
  const navigate = useNavigate()
  const queries = queryString.parse(window.location.search)
  const url = queries.url as string
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (code) {
      const codeType = code.match(/^QR-/g) ? 'qr' : 'nfc'
      if (codeType === 'qr') {
        const qrCode = code.replace(/^QR-/g, '')
        createQrLogApi(qrCode)
        getQrContents(qrCode)
          .then((data) => {
            if (data && data.document) {
              // 이동할땐 prefix(QR-)가 붙는게 맞음
              navigate(`/${code}/document/${data?.document?.id}`)
            }
            if (data && data.place) {
              // 이동할땐 prefix(QR-)가 붙는게 맞음
              navigate(`/${code}/place/${data?.place?.id}`)
            }
            if (data && data.link) window.location.href = data.link
          })
          .catch(() => {
            setLoading(false)
            navigate('/not-found')
          })
      } else {
        createNfcLogApi(code)
        getNfcContents(code)
          .then((data) => {
            switch (data?.contents[0].type) {
              case NfcContentTypeEnum.Document:
                navigate(`/${code}/document/${data.contents[0].documentId}`)
                break
              case NfcContentTypeEnum.Place:
                setLoading(false)
                navigate(`/${code}/place/${data?.contents[0].placeId}`)
                break
              case NfcContentTypeEnum.SignBoard:
                setLoading(false)
                navigate(
                  `/${code}/place/${data?.contents[0].signBoard?.placeId}`
                )
                break
              case NfcContentTypeEnum.Link:
                window.location.href =
                  data?.contents[0].contents ||
                  `${process.env.REACT_APP_SCAN_URL}/not-found`
                break
              default:
                setLoading(false)
                navigate('/not-found')
                break
            }
          })
          .catch((err) => {
            setLoading(false)
            navigate('/not-found')
          })
      }
    }
    if (!code && !url) {
      navigate('/not-found')
    }
  }, [code, navigate, url])

  if (loading && !url) {
    return <SpinnerPage />
  }

  if (url) {
    window.location.href = url
  }

  return (
    <div className="relative">
      {nfcInfo?.contents.length === 1 &&
        nfcInfo?.contents[0] &&
        nfcInfo?.contents[0].type === NfcContentTypeEnum.Document && (
          <Page
            contentType={nfcInfo?.contents[0].type}
            contentId={nfcInfo?.contents[0].contentId}
            contents={nfcInfo?.contents[0].contents}
          />
        )}
    </div>
  )
}

export default Entrance
