import { api } from '../lib/api'

export const createNfcLogApi = async (nfcId: string): Promise<void> => {
  const agent = navigator.userAgent
  return api.post(`/nfc-log`, {
    nfcId,
    deviceInfo: agent,
  })
}
export const createQrLogApi = async (qrId: string): Promise<void> => {
  const agent = navigator.userAgent
  return api.post(`/qr-log`, {
    qrId,
    deviceInfo: agent,
  })
}

export default { createNfcLogApi }
