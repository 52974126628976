import React, { ReactNode } from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function DoorIcon({ className }: IconProps): JSX.Element {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6665 16.5H5.33317M5.1665 16.5H8.83317C10.2333 16.5 10.9334 16.5 11.4681 16.2275C11.9386 15.9878 12.321 15.6054 12.5607 15.135C12.8332 14.6002 12.8332 13.9001 12.8332 12.5V5.5C12.8332 4.09987 12.8332 3.3998 12.5607 2.86502C12.321 2.39462 11.9386 2.01217 11.4681 1.77248C10.9334 1.5 10.2333 1.5 8.83317 1.5H5.1665C3.76637 1.5 3.06631 1.5 2.53153 1.77248C2.06112 2.01217 1.67867 2.39462 1.43899 2.86502C1.1665 3.3998 1.1665 4.09987 1.1665 5.5V12.5C1.1665 13.9001 1.1665 14.6002 1.43899 15.135C1.67867 15.6054 2.06112 15.9878 2.53153 16.2275C3.06631 16.5 3.76637 16.5 5.1665 16.5ZM9.49984 8.16667V9H10.3332V8.16667H9.49984Z"
        stroke="#94A3B8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DoorIcon
