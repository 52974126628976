import { SnsType } from '../enums/common.enum'
import { api } from '../lib/api'
import { getUserId } from '../lib/auth.lib'
import { SignupByPhoneType } from './types/signup.type'

export const signupByPhoneApi = async (data: SignupByPhoneType) => {
  const tempUserId = getUserId()
  return api.patch(`/signup/by-phone`, { ...data, tempUserId })
}

export const checkUserApi = async (phoneNumber: string): Promise<boolean> => {
  return api.post<{ phoneNumber: string }, boolean>(`/signup/check/phone`, {
    phoneNumber,
  })
}

export const signupSnsApi = async (type: SnsType, snsId: string) => {
  return api.post(`/v1/auth/signup/${type}`, { snsId })
}

export default { signupByPhoneApi }
