import { api } from '../lib/api'
import { SigninByPhoneType } from './types/signin.type'

type SigninDto = {
  accessToken: string
}

export const signinByPhoneApi = async (
  data: SigninByPhoneType
): Promise<SigninDto> => {
  return api.post<SigninByPhoneType, SigninDto>(`/v1/auth/login/by-phone`, data)
}

export default { signinByPhoneApi }
