import React, { HTMLAttributes } from 'react'

type PlaceListItemNotFoundProps = HTMLAttributes<HTMLElement>

function PlaceListItemNotFound(props: PlaceListItemNotFoundProps) {
  const { children } = props
  return (
    <li>
      <span className="flex flex-row flex-1 item-center gap-2">{children}</span>
    </li>
  )
}

export default PlaceListItemNotFound
