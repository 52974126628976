import { api } from '../lib/api'

export const addPlaceHistoryApi = async (
  userId: string,
  placeId: string
): Promise<void> => {
  return api.post(`/place-history`, { userId, placeId })
}

export default {}
