import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { OsType } from '../../enums/common.enum'
import { checkOs, classNames, checkApp } from '../../helpers/common.helper'

function AppGuide() {
  const os: OsType = checkOs()
  const isApp = checkApp()
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    setIsShow(!isApp && os === OsType.IOS)
    setTimeout(() => {
      // const disabled = localStorage.getItem('install-popup')
      // if (disabled) return
      // const now = dayjs(new Date())
      // const expired = now.add(1, 'day')
      // localStorage.setItem(
      //   'install-popup',
      //   expired.format('YYYY-MM-DD HH:mm:ss')
      // )
    }, 1000)
  }, [isApp, os])

  const handleInstallButtonClick = () => {
    switch (os) {
      case OsType.IOS:
      default:
        window.open(
          'https://apps.apple.com/kr/app/%EB%8B%A4%EA%B0%80%EB%8B%A4-%EB%A6%AC%EB%8D%94/id1639049707',
          '_blank'
        )
        break
    }
  }

  const handleClose = () => {
    setIsShow(false)
  }
  if (!isShow) {
    return (
      <div className="intro-dagada-card flex flex-col mb-2 items-center text-center bg-slate-100">
        <img
          className="card-logo"
          alt="다가다 로고"
          src="/assets/images/dagada-logo.svg"
          aria-hidden
        />
        <img
          className="card-logo-text"
          alt="다가다"
          src="/assets/images/dagada-text.svg"
          aria-hidden
        />

        <h2 className="text-lg text-slate-450">다가다 리더 소개</h2>
        <p className="text-base font-light">
          다가다 리더는 근거리무선통신을 이용하여 사용자에게 사물이나 제작물이
          가진 정보를 정확하게 안내하는 정보 접근성 도구입니다.
        </p>
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'flex flex-col items-center p-8 mx-5 mb-2 gap-4 bg-teal-700 rounded-2xl text-white',
        isShow ? '' : 'hidden'
      )}
    >
      {/* <button
        type="button"
        className="absolute top-6 right-6"
        onClick={handleClose}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 0C6.72893 0 0 6.72893 0 15C0 23.2711 6.72893 30 15 30C23.2711 30 30 23.2711 30 15C30 6.72893 23.2711 0 15 0ZM15 27.8571C7.9106 27.8571 2.14286 22.0894 2.14286 15C2.14286 7.9106 7.9106 2.14286 15 2.14286C22.0894 2.14286 27.8571 7.9106 27.8571 15C27.8571 22.0894 22.0894 27.8571 15 27.8571Z"
            fill="white"
          />
          <path
            d="M19.7697 10.409C19.3436 9.98296 18.6529 9.98296 18.2269 10.409L15.0894 13.5465L11.9517 10.4089C11.5257 9.98284 10.835 9.98284 10.4089 10.4089C9.98284 10.835 9.98284 11.5257 10.4089 11.9517L13.5465 15.0894L10.4089 18.227C9.98284 18.6531 9.98284 19.3437 10.4089 19.7698C10.6219 19.9828 10.9012 20.0893 11.1803 20.0893C11.4595 20.0893 11.7386 19.9828 11.9517 19.7698L15.0894 16.6322L18.227 19.7698C18.44 19.9828 18.7192 20.0893 18.9984 20.0893C19.2776 20.0893 19.5567 19.9828 19.7698 19.7698C20.1959 19.3437 20.1959 18.6531 19.7698 18.227L16.6322 15.0894L19.7698 11.9517C20.1958 11.5258 20.1958 10.835 19.7697 10.409Z"
            fill="white"
          />
        </svg>
      </button> */}
      <img className="w-14" src="/assets/icons/dagada-logo.png" alt="" />
      <p className="text-2xl">앱 설치하고 편리하게 이용하세요!</p>
      <p className="text-base">
        리더 앱을 설치하시면 기록을 관리하고
        <br />
        즐겨찾기로 편리하게 이용하실 수 있습니다.
      </p>
      <button
        type="button"
        className="py-3 px-9 bg-teal-800 text-base rounded-full"
        onClick={handleInstallButtonClick}
      >
        앱 설치하기
      </button>
    </div>
  )
}

export default AppGuide
