/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { PlaceType } from '../../enums/place.enum'
import { convertPlaceTitle } from '../../services/place.service'
import { Place } from '../../types/Place'
import BuildingIcon from '../icons/building.icon'

interface TitleCardProps {
  place?: Place
}

function TitleCard(props: TitleCardProps) {
  const { place } = props
  if (!place) return <></>

  return (
    <div className="flex flex-col py-6 px-6 gap-2.5 bg-teal-600 text-white rounded-2xl">
      {place.building && (
        <h2 className="flex flex-row items-center gap-2 text-xl font-bold">
          <BuildingIcon className="w-3.5" />
          {place.building.title}
        </h2>
      )}
      <h3 className="flex flex-row items-center gap-2 text-3xl font-bold">
        {place.type === PlaceType.Building && <BuildingIcon className="w-6" />}
        {convertPlaceTitle(place.title)}
      </h3>
      {place.floor && (
        <span className="text-xl font-semibold">
          {convertPlaceTitle(place.floor.title)}
        </span>
      )}
    </div>
  )
}

export default TitleCard
