import { OsType } from '../enums/common.enum'

export function classNames(...classes: (string | null | undefined)[]) {
  return classes.filter(Boolean).join(' ')
}

export const getColor = (hasError?: boolean): string => {
  if (hasError) return 'red'
  return 'slate'
}
export const checkLocation = (path: string, href: string, exact = false) => {
  if (exact) return path === href
  return path.indexOf(href) > -1
}

export const checkOs = (): OsType => {
  const mobileType = navigator.userAgent.toLowerCase()

  if (mobileType.indexOf('android') > -1) {
    return OsType.ANDROID
  }
  if (
    mobileType.indexOf('iphone') > -1 ||
    mobileType.indexOf('ipad') > -1 ||
    mobileType.indexOf('ipod') > -1
  ) {
    return OsType.IOS
  }

  return OsType.IOS
}
export const checkApp = (): boolean => {
  return !!navigator.userAgent.match(/(UserId|DeviceId|DeviceType|OsVersion)/g)
}

export default { classNames, getColor, checkLocation, checkOs, checkApp }
