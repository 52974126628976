import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth'
import { atom } from 'recoil'

export const recaptchaState = atom<RecaptchaVerifier>({
  key: 'recaptchaState',
  default: undefined,
})

export const confirmationState = atom<ConfirmationResult | null>({
  key: 'confirmationState',
  default: null,
})

export const signupPhoneNumberState = atom<string | null>({
  key: 'signupPhoneNumberState',
  default: null,
})

export default { recaptchaState }
