import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function ClockIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 5V9L11.5 10.5M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z"
        stroke="#131A29"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ClockIcon
