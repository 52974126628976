import { Union } from '../types/Common'

export enum OsType {
  ANDROID = 'android',
  IOS = 'ios',
}

export enum SnsEnum {
  NAVER = 'naver',
  KAKAO = 'kakao',
  APPLE = 'apple',
  GOOGLE = 'google',
}
export type SnsType = Union<typeof SnsEnum>

export default { OsType }
