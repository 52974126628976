import { atom } from 'recoil'

export type AlertPropType = {
  title: string
  desc?: string
  onClick?: () => Promise<void>
  confirm?: {
    confirmClick: () => Promise<void>
    cancelClick?: () => Promise<void>
  }
}

export const alertState = atom<AlertPropType | null>({
  key: 'alertState',
  default: null,
})

export default { alertState }
