import React, { SVGAttributes } from 'react'
import { classNames } from '../../helpers/common.helper'

export type IconProps = SVGAttributes<HTMLOrSVGElement> & {
  path?: string
  alt?: string
  className?: string
}
function Icon({ path, alt, className }: IconProps) {
  return <img src={path} alt={alt || ''} className={classNames(className)} />
}

export default Icon
