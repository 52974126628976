import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PlaceView from '../pages/PlaceView'
import Entrance from '../pages/Entrance'
import NotFound from '../pages/NotFound'
import Layout from '../components/layout/Layout'
import SignUp from '../pages/Signup'
import SignupPhone from '../pages/SignupPhone'
import FullHeightLayout from '../components/layout/FullHeightLayout'
import CreateUser from '../components/signup/CreateUser'
import Signin from '../pages/Signin'
import Agree from '../pages/Agree'
import AgreeSns from '../pages/AgreeSns'
import DocumentView from '../pages/DocumentView'

export default function Router(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<FullHeightLayout />}>
          <Route path="/signin" element={<Signin />} />
          <Route>
            <Route path="sns/:type/:snsId" element={<AgreeSns />} />
            <Route path="agree/:type" element={<Agree />} />
            <Route path="email" element={<SignUp />} />
            <Route path="phone" element={<SignupPhone />} />
            <Route path="user" element={<CreateUser />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          <Route path="/not-found" element={<NotFound />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/">
            <Route index element={<Entrance />} />
            <Route path="/:code">
              <Route index element={<Entrance />} />
              <Route path="place/:id" element={<PlaceView />} />
              <Route path="document/:id" element={<DocumentView />} />
            </Route>
          </Route>
          <Route path="/place/:id" element={<PlaceView />} />
          <Route path="/document/:id" element={<DocumentView />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
