import Joi from 'joi'
import lang from './lang'

export default function validator() {
  return Joi.defaults((schema) => {
    return schema.messages({
      'any.required': lang().getMessage('required'),
      'string.empty': lang().getMessage('required'),
      'string.email': lang().getMessage('email'),
      'string.min': lang().getMessage('min'),
    })
  })
}
