import React from 'react'
import { classNames } from '../../helpers/common.helper'

interface IconProps {
  className?: string
}
function ArrowIcon({ className }: IconProps) {
  return (
    <svg
      className={classNames(className)}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.5 13L23.5 20L16.5 27" stroke="#CBD5E1" strokeWidth="2" />
    </svg>
  )
}

export default ArrowIcon
