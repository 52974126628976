import React, { useEffect, useState } from 'react'
import { SecondsButtonStyle } from '../../styles/button.style'
import { PlaceListItemType } from '../../types/Place'
import Button from '../buttons/Button'
import PlaceListItem from './PlaceListItem'
import PlaceListItemNotFound from './PlaceListItemNotFound'

type PlaceListProps = {
  items: PlaceListItemType[]
  limit: number
  notFoundMessage: string
  moreButtonMessage: string
}

export default function PlaceList({
  items,
  limit,
  notFoundMessage,
  moreButtonMessage,
}: PlaceListProps) {
  const [page, setPage] = useState<number>(1)
  const total = items.length

  const handleListMore = (p: number, l: number, t: number) => {
    if (t < (p - 1) * l) return
    setPage(p + 1)
  }

  useEffect(() => {
    setPage(1)
  }, [items])

  return (
    <>
      <div>
        <ul className="flex flex-col gap-1">
          {total === 0 && (
            <PlaceListItemNotFound>{notFoundMessage}</PlaceListItemNotFound>
          )}
          {items.slice(0, page * limit).map((item) => {
            return <PlaceListItem key={item.id} place={item} />
          })}
        </ul>
      </div>
      {total > page * limit && (
        <Button
          className={SecondsButtonStyle}
          onClick={() => handleListMore(page, limit, total)}
        >
          {moreButtonMessage}
        </Button>
      )}
    </>
  )
}
